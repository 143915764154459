/* Provide sufficient contrast against white background */

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

body {
    background-color: #E7EDF3
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.container {
    /* margin-top: 50px;*/
}

.container-fluid {
    height: 100%;
}

.questionnaire-banner {
    background: #004B8B;
    color: white;
    padding: 0px;
}

.questionnaire-banner-aerial-inner {
    background: none;
    color: white;
    padding: 0px;
}

.questionnaire-banner-aerial {
    background-image: url("/images/GSTT-banner-556x180px.png");
    background-color: #004B8B;
    background-position: right 0px bottom 0px;
    min-height: 180px;
}

.questionnaire-logo img {
    height: 40px;
    width: 66px;
}

.questionnaire-bannertext {
    font-size: 16pt;
    font-family: wf_segoe-ui_light, "Segoe UI Light", "Segoe WP Light", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
    text-shadow: 2px 2px #000000;
}

.questionnaire-bannertext-med {
    font-size: 18pt;
    font-family: wf_segoe-ui_light, "Segoe UI Light", "Segoe WP Light", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
    font-weight: bold;
    text-shadow: 2px 2px #000000;
    padding-top: 80px;
}

.questionnaire-body {
    padding-top: 20px;
    padding-bottom: 30px;
}

.questionnaire-required-symb {
    color: red;
    font-size: 14pt;
    font-weight: bold;
}

.questionnaire-blurb-content {
    color: #032d63;
    font-size: 21px;
    padding-top: 15px;
    padding-bottom: 40px;
    font-family: wf_segoe-ui_light, "Segoe UI Light", "Segoe WP Light", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
}

.questionnaire-blurb-important {
    font-weight: bold;
    color: #ff0000;
}

.questionnaire-blurb-emphasis {
    font-weight: bold;
    color: navy;
}

.question-text {
    font-family: wf_segoe-ui_normal, "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
    font-size: 17px;
    margin-bottom: 10px;
}

.question-text-mandatory {
    background-color: pink;
    font-weight: 500;
}

.questionnaire-answers {
    margin-left: 3rem;
}

.questionnaire-warning {
    color: darkorange;
}

.questionnaire-error {
    color: red;
}

.questionnaire-error-attention {
    background-color: pink;
    padding: 5px;
}

.questionnaire-warning-attention {
    background-color: khaki;
    padding: 5px;
}

.questionnaire-results-list {
    width: 100%;
}

.questionnaire-submission-summary {
    padding-top: 20px;
    padding-bottom: 20px;
}

.questionnnaire-answer-image {
    margin-bottom: 20px;
    max-width: 300px;
}

.questionnaire-results-smalltext {
    font-size: 8pt;
}

.login-link {
    color: white;
}

    .login-link:hover {
        color: white;
    }

    .login-link:visited {
        color: white;
    }

.login-independent-view {
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 999;
    background: white;
    opacity: 0.8;
    max-width: unset;
    flex: unset;
    width: unset;
}

    .login-independent-view .login-link {
        color: blue;
    }

.logon-error {
    color: red;
}

.hospital-number-inputbox {
    width: 150px;
    display: inline;
}

.date-selection-inputbox {
    width: 140px;
}

.date-override-container {
    padding-left: 20px;
}

.submission-filter-bar {
    padding-bottom: 20px;
}

.submission-filter-bar-separator {
    margin-bottom: 20px;
}

.filter-button-container {
    min-width: 80px;
}

.filter-control-inline {
    display: unset;
    width: unset;
}

.answer-reason {
    width: 150%;
}

.answer-address {
    width: 150%;
}

.answer-availability-check {
    margin-left: 0;
    position: relative;
    margin-left: 4px;
    margin-right: 4px;
}

.standard-checkbox {
    position: unset;
    margin-left: unset;
    margin-right: 5px;
}

.guidance-problem-informational {
    font-weight: bold;
    color: darkred;
}

.guidance-problem-warning {
    color: red;
}

.routine {
    background-color: #DA291C;
}

.new {
    background-color: #009639;
    animation: newflash linear s infinite;
}

.queue-registration-checkbox {
    margin-left: 0px;
}

.queue-registration-name {
    margin-top: 5px;
}

.queue-registration-input {
    width: unset;
    margin-right: 10px;
    display: unset;
}

.queue-registration-row {
    margin-bottom: 3px;
}

.prescriber-conflict {
    font-size: 24pt;
    color: red;
    position: absolute;
    left: 0px;
    top: 0px;
    background: white;
    font-weight: bold;
    opacity: 0.8;
    z-index: 9999;
}

@keyframes newflash {
    0% {
        background-color: #009639;
    }

    50% {
        background-color: #41e17e;
    }

    100% {
        background-color: #009639;
    }
}

.urgent {
    background-color: #DA291C;
    animation: urgentflash linear 4s infinite;
}

@keyframes urgentflash {
    0% {
        background-color: #DA291C;
    }

    50% {
        background-color: #f99d96;
    }

    100% {
        background-color: #DA291C;
    }
}

.table-schedule-utilisation {
    width: 100%;
    font-size: 10pt;
}

.table-schedule-utilisation th, td {
    border: 1px solid #c0c0c0;
    border-collapse: collapse;
    padding: 3px;
}

.table-schedule-utilisation-slotbox {
    display: unset;
    width: 90px;
    height: 20px;
}

.table-schedule-utilisation-break {
    background-color: navy;
    color: white;
    font-weight: bold;
}

.table-schedule-utilisation-break-unavailable {
    background-color: #333333;
    color: white;
    font-weight: bold;
}

.table-schedule-utilisation-daychanges {
    width: 50%;
    vertical-align: top;
}

.table-schedule-utilisations-slotchangedetected {
    font-weight: bold;
    color: red;
}

.table-schedule-utilisation-walkin {
    background-color: lightskyblue;
}

.table-schedule-utilisation-addon {
    background-color: goldenrod;
}

.schedule-utilisation-success {
    margin-left: 30px;
    color: green;
    font-weight: bold;
}

.schedule-utilisation-slotcopy {
    padding: 0.05rem 0.35rem !important;
    font-size: 0.6rem !important;
}

.schedule-utilisation-dropdown {
    width: unset;
    display: unset;
}

.site-management-textbox {
    margin: 3px;
}

.site-management-checkbox {
    margin: 3px;
}

.site-management-smalltext {
    font-size: 8pt;
    font-weight: bold;
}

.psd-batch-no {
    width: 200px;
}

.psd-expiry {
    width: 200px;
}

.psd-input-row {
    height: 60px;
}

.psd-footer {
    color: #333333
}

.version-mismatch-warning {
    position: absolute;
    left: 0px;
    top: 0px;
    background: white;
    color: darkred;
    font-weight: bold;
    opacity: 0.8;
    z-index: 9999;
}

.disconnect-warning {
    position: absolute;
    left: 5px;
    top: 0px;
    background: white;
    color: red;
    font-weight: bold;
    font-size: 18pt;
    opacity: 0.8;
    z-index: 9999;
}

@media (min-width: 576px) {
    .questionnaire-bannertext {
        font-size: 20pt;
        padding-left: 10px;
    }

    .questionnaire-bannertext-med {
        max-width: 80%;
        flex: 0 0 80%;
        font-size: 17pt;
        padding-left: 30px;
        padding-top: 55px;
    }

    .questionnaire-banner {
        padding: 10px;
    }

    .questionnnaire-answer-image {
        max-width: unset;
    }

    .questionnaire-banner-aerial {
        background-image: url("/images/GSTT-banner-538x180px.png");
        background-color: #004B8B;
        background-position: right 0px bottom 0px;
        min-height: 180px;
    }
}

@media (min-width: 768px) {
    .questionnaire-logo {
        display: initial;
    }

    .questionnaire-bannertext {
        max-width: 75%;
        flex: 0 0 75%;
        font-size: 30pt;
        padding-left: 30px;
    }

    .questionnaire-bannertext-med {
        max-width: 80%;
        flex: 0 0 80%;
        font-size: 24pt;
        padding-left: 30px;
        padding-top: 45px;
    }

    .questionnaire-banner {
        padding: 20px;
    }

    .questionnnaire-answer-image {
        max-width: unset;
    }

    .questionnaire-logo img {
        height: 75px;
        width: 125px;
    }

    .questionnaire-banner-aerial {
        background-image: url("/images/GSTT-banner-708x180px.png");
        background-color: #004B8B;
        background-position: right 0px bottom 0px;
        min-height: 180px;
    }
}

@media (min-width: 992px) {
    .questionnaire-logo {
        display: initial;
    }

    .questionnaire-banner {
        padding: 20px;
    }

    .questionnaire-bannertext {
        max-width: 75%;
        flex: 0 0 75%;
        font-size: 36pt;
        padding-left: 30px;
        padding-top: 55px;
    }

    .questionnaire-bannertext-med {
        max-width: 80%;
        flex: 0 0 80%;
        font-size: 26pt;
        padding-left: 30px;
        padding-top: 45px;
    }

    .questionnnaire-answer-image {
        max-width: unset;
    }

    .questionnaire-logo img {
        height: 120px;
        width: 200px;
    }

    .questionnaire-banner-aerial {
        background-image: url("/images/GSTT-banner-958x180px.png");
        background-color: #004B8B;
        background-position: right 0px bottom 0px;
        min-height: 180px;
    }
}

@media (min-width: 1200px) {
    .questionnaire-logo {
        display: initial;
    }

    .questionnaire-banner {
        padding: 20px;
    }

    .questionnaire-bannertext {
        max-width: 75%;
        flex: 0 0 75%;
        font-size: 40pt;
        padding-left: 60px;
        padding-top: 50px;
    }

    .questionnaire-bannertext-med {
        max-width: 75%;
        flex: 0 0 75%;
        font-size: 30pt;
        padding-left: 60px;
        padding-top: 40px;
    }

    .questionnnaire-answer-image {
        max-width: unset;
    }

    .questionnaire-logo img {
        height: auto;
        width: auto;
    }

    .questionnaire-banner-aerial {
        background-image: url("/images/GSTT-banner-1138x180px.png");
        background-color: #004B8B;
        background-position: right 0px bottom 0px;
        min-height: 180px;
    }
}

#screeningAdminView {
    display: block;
}

#screeningPrintView {
    display: none;
}

#bannerHeader {
    display: block;
}

.hideExceptForPrint {
    display: none;
}

.assessorContainer {
    display: flex;
    flex-flow: column;
    height: 100vh;
    background: #E8EDEE
}

@media screen {
    .print-custom-footer {
        display: none;
    }
}
.print-custom-footer, .print-custom-footer-space {
    height: 20px;
   
}


.print-custom-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.print-custom-table {
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    border-collapse:collapse;
}

@media screen {
    .print-custom-footer {
        position: relative;
    }
}




@media print {
    @page {
        size: A4;
    }
    .version-mismatch-warning {
        display: none;
    }

    #screeningAdminView {
        display: none;
    }

    #screeningPrintView {
        display: block;
    }

    #bannerHeader {
        display: none;
    }

    #assessorNav {
        display: none;
    }

    .hideExceptForPrint {
        display: block;
    }

    .pageBreak {
        clear: both;
        page-break-after: always;
    }

    .assessorContainer {
        height: unset;
    }

    .login-independent-view {
        display:none;
    }
    .print-custom-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    .questionnaire-body {
    padding:0;
    }

    .table-schedule-utilisation th, td {
    border:none;
    }

    .copyRightText {
        display:none;
    }

    .print-page-spacer {
       min-height:400px
    }

    .card {
    border:none;
    overflow: hidden;
    }

    .card-body {
    padding-top:0 !important;
    }
}


.psdBgPrimary {
    background: #9cc2e5;
}

.psdBgGrey {
    background: #acb9ca;
}

.psdBgLightGrey {
    background: #d9d9d9;
}

.text-Primary {
    color: #1b6ec2;
}

.text-danger{
    color:red

}

.text-success {
    color: green
}

.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border: 1px solid #444;
}

.assessorMainRow {
    height: 100%;
    background: #E8EDEE;
    padding-left: 15px;
    padding-bottom: 15px;
}

/*Greeters*/

.greaterMainRow {
    height: 100%;
    background: #231f20;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.greaterMainCol {
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.StatFigures {
    font-size: 2vh;
}

.top {
    border-top: 3px solid !important;
    border-color: red !important;
}

.bottom {
    border-bottom: 3px solid !important;
    border-color: red !important;
}

.left {
    border-left: 3px solid !important;
    border-color: red !important;
}

.right {
    border-right: 3px solid !important;
    border-color: red !important;
}

.w30 {
    width: 30px !important;
}

.w180 {
    width: 180px !important;
}

.w300 {
    width: 300px !important;
}



.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.75rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.75rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.noPadding {
    padding: 0;
    margin: 0;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

.logoPanel {
    padding-top: 38px
}

.notificationPanel {
    margin-left: 15px !important;
    position: absolute;
    bottom: 120px;
    left: 15px;
    right: 15px;
}

@media only screen and (max-width: 1200px) {
    .notificationPanel {
        display: none;
    }
}


.hfs {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}


.cardCustomBorder {
    border: 2px solid rgba(0, 0, 0, 0.125) !important;
}

.logoWidth {
    width: 100%;
}

.responsive {
    width: 100%;
    padding: 0 20px 0 20px;
    height: auto;
}


/*Greeters*/
.greaterMainRow {
    height: 70%;
    background: #231f20;
    padding: 15px;
}

.greaterMainCol {
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.StatFigures {
    font-size: 4vh;
}

.statsCards {
    background: #41B6E6;
    color: #fff;
    font-size: 1.5vh;
}

.stickyHead {
    position: sticky !important;
    top: 0 !important;
    background: #ffffff !important;
}

.noPaddingCardBody {
    padding: 0 !important;
}

.cardHeight {
    height: calc(100vh - 20px);
}

.pointer {
    cursor: pointer;
}

.blueIcon {
    color: #00A9CE;
}


.uploadDiv {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    margin-top: -9em;
    margin-left: -15em;
    border: 1px solid #ccc;
    background-color: #f3f3f3;
}

.dashboard-card {
    margin-bottom: 30px;
    border: 10px solid;
    text-align: center;
}

.dashboard-chart-container {
    max-height: 100%;
    height: 850px;
}

.dashboard-chart-main-stats {
    margin-left: 40%;
    margin-top: 10px;
}

.dashboard-title a, .dashboard-title a:hover, .dashboard-title a.visited {
    text-decoration: none;
    font-weight: bold;
    color: black;
}

.dashboard-table-stats td, .dashboard-table-stats th {
    padding: 3px;
}

.dashboard-table-totals {
    padding: 3px;
    margin-left: 80px;
    margin-top: 10px;
    width: 90%;
}

.dashboard-table-online {
    color: green;
}

.dashboard-table-offline {
    color: #555;
}

.rag-status-green {
    color: #00B050;
    font-weight: bold;
}
.rag-status-amber {
    color: #DD8844;
    font-weight: bold;
}
.rag-status-red {
    color: red;
    font-weight: bold;
}
.rag-status-black {
    color: black;
    font-weight: bold;
}
.rag-status-unknown {
    color: black;
    font-weight: normal;
}

.rag-card-green {
    border-color: #00B050;
    background-color: #AFFFD3;
}
.rag-card-amber {
    border-color: #FFC000;
    background-color: #FFF2CC;
}
.rag-card-red {
    border-color: red;
    background-color: #FFC9C9;
}
.rag-card-black {
    border-color: black;
    background-color: #D9D9D9;
}
.rag-card-unknown {
    border-color: black;
    background-color: white;
}

.rag-card-disabled {
    border-color: dimgray;
    background-color: lightgrey;
    color: gray !important;
}

.rag-card-hidden {
    display: none;
}

.rag-card-disabled span, .rag-card-disabled a {
    color: gray !important;
}

.hub-dashboard-chart-label {
    font-weight: bold;
}

.hub-config-error {
    color: red;
    font-weight: bold;
}

.hub-config-success {
    color: green;
    font-weight: bold;
}

.hub-config-location-assessor {
    width: 100px;
}

.hub-config-rag-input {
    width: 120px;
}

.splitList {
    list-style-type: none;
    font-size: 2rem;
    -moz-column-count: 4;
    -moz-column-gap: 20px;
    -webkit-column-count: 4;
    -webkit-column-gap: 20px;
    column-count: 4;
    column-gap: 20px;
    padding-left: 0;
}


@media (max-width: 992px) {
    .splitList {
        -moz-column-count: 3;
        -moz-column-gap: 20px;
        -webkit-column-count: 3;
        -webkit-column-gap: 20px;
        column-count: 3;
        column-gap: 20px;
    }
}


@media (max-width: 576px) {
    .splitList {
        -moz-column-count: 2;
        -moz-column-gap: 20px;
        -webkit-column-count: 2;
        -webkit-column-gap: 20px;
        column-count: 2;
        column-gap: 20px;
    }
}

